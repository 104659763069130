html,
body {
  background-color: #212121;
  color: rgba(255, 255, 255, 1);
  font-family: OpenSans, sans-serif;
  font-size: 16px;
  height: 100vh;
  overflow-x: hidden;
  width: 100vw;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
